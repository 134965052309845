import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";

import "./firebaseui.css"
import DashContainer from "./Screens/DashContainer";
import firebase from 'firebase/compat/app';
import './scss/fonts/embedFonts.css'





if (process.env.NODE_ENV === 'development') {
  window.$ipRiskAPI = 'http://localhost:8080'


}

if (process.env.NODE_ENV === 'production') {
  window.$ipRiskAPI = 'https://datasnack.uc.r.appspot.com'
}


const App = ({ app, ui }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [user, setUser] = useState({})



  const signOut = () => {
    app.signOut()
  }

  useEffect(() => {
    console.log("App.js useEffect called w/ listener")
    let unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        setUser(user)
        setIsAuthenticated(true)
        console.log("user", user)

        window.$tokenRefreshTime = user.multiFactor.user.auth.currentUser.stsTokenManager.expirationTime
        console.log("expiration time", window.$tokenRefreshTime)
        console.log("is expired", user.multiFactor.user.auth.currentUser.stsTokenManager.isExpired)
        localStorage.setItem('token', user.multiFactor.user.accessToken)
        console.log("USER ID:", user.multiFactor.user.uid)
        if (user.multiFactor.user.emailVerified) {
          setIsEmailVerified(true)
        }

      } else {
        console.log("Problem getting token")
      }
    })

    return () => {
      unsubscribe();
    }

  }, [])



  return (
    <div>
{/* 

      <data-snack-widget /> */}



      <BrowserRouter>

        <Routes>

          <Route path="/" element={<DashContainer user={user} isAbout={false} 
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />


          <Route path="/contact" element={<DashContainer user={user} isAbout={false} goToPage={"Contact"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

          <Route path="/blog" element={<DashContainer user={user} isAbout={false} goToPage={"Blog"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />

       

          <Route path="*" element={<DashContainer user={user} isAbout={false}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}



export default App;
