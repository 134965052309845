import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig.js'
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { Stack,  Typography,  Box, Button } from '@mui/material';
import useWindowDimensions from '../Screens/useWindowDimensions.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../Screens/recordButton.css'
import RecordButton from '../Components/RecordButton.js';
import WebcamCapture from '../Components/Webcam.js';




const Interview = ({
    isAuthenticated,
    openLogin,
    setShowMessages,
    handleScreenChangeClick,
    topPadding

}) => {

   
  
    const { height, width } = useWindowDimensions();
    const [token, setToken] = useState(null)

   
   


useEffect(()=>{
    console.log("in Interview")
    window.scrollTo(0, 0)
})

    useEffect(() => {
        let myToken = localStorage.getItem('token');
        setToken(myToken)

    }, [isAuthenticated])

    return (
        <Box style={{
            backgroundImage: `linear-gradient(to bottom, #212325 50%, #0f0f0f 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: height,


        }}>

            {/* Back button */}
            <Stack
                direction={"row"}
                paddingX={window.$isMobile ? 2.5 : 5}
                paddingTop={2}
                // alignItems={"flex-end"}
                justifyContent={"flex-start"}>
                <ArrowBackIcon
                    onClick={() => {
                        console.log("HomePage")
                        handleScreenChangeClick({
                            selectedIndex: "HomePage"
                        })
                    }}
                    sx={{
                        fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                        opacity: 0.3
                    }} />


            </Stack>
            {/* Content Block */}
            <Stack

                paddingX={window.$isMobile ? 5 : "20%"}
                style={{
                    display: "flex",
                    minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: -80,
                    width: "100%"
                }}
                direction="column"
                justifyContent={"center"} alignItems={"flex-start"}>
                <Typography
                    paddingBottom={2}
                    variant={'h2'}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                      Snack<span style={{ color: '#66a8da' }}>{window.$isMobile && <br />}Stories</span>
                </Typography>
                <Typography
                    variant={'body'}
                    paddingBottom={window.$isMobile ? 6 : 6}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 400 : 400 },
                        lineHeight: '120%', color: "#EBEBEB"
                    }} fontStyle={"normal"}>
                   To create your SnackStory, press the microphone button to start. When finished, click the microphone button again to stop. DataSnack will then process your story and create a slightly embellished version of your adventures. Finally, share your unique story with friends!

                </Typography>
                {/* <WebcamCapture/> */}

               <RecordButton 
                isAuthenticated={isAuthenticated}
                openLogin={openLogin}
                setShowMessages={setShowMessages}
                handleScreenChangeClick={handleScreenChangeClick}
               
               />

              
            </Stack>






        </Box>



    )

}



export default Interview