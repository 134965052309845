import React, { useState } from 'react'


import {Stack, IconButton, Button, Typography } from '@mui/material';
import logo from '../assets/images/DataSnack-full-blue.png'

import MenuOpenIcon from '@mui/icons-material/MenuOpen';




const Header = ({ isAuthenticated, currentScreen, handleDrawerToggle, handleScreenChangeClick, setShowMessages, user }) => {

   

    return (

        <div

            style={{
                display: "flex", width: "100%",


            }}
        >

            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}  >

                <Stack onClick={() => {
                   handleScreenChangeClick({
                    selectedIndex: "HomePage"
                }
                )
                }} style={{ cursor: "pointer", display: "flex", flexDirection: "row", width: "20%", alignItems: "center" }}>
                    <img src={logo} alt="Location Image" style={{ width: '180px', paddingRight: "15px" }} />

                </Stack>
               
                    <Stack direction={"row"} spacing={2}>
                      
                    <IconButton
                        sx={{
                            fontSize: 20,
                            color: "#006ec2",
                            opacity: 0.6
                        }}
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    // sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuOpenIcon />
                    </IconButton>
                    </Stack>


            </Stack>

         


          
           

        </div>


    )


}

export default Header