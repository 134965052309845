// Install react-webcam by running: npm install react-webcam  
  
import React, { useEffect, useRef, useState } from 'react';  
import Webcam from 'react-webcam';  
  
const WebcamCapture = () => {  
  const webcamRef = useRef(null);  
  const [capturedImages, setCapturedImages] = useState([]);  
  
  useEffect(() => {  
    const interval = setInterval(() => {  
      capture();  
    }, 5000); // Capture every 5 seconds  
  
    // Cleanup interval on component unmount  
    return () => clearInterval(interval);  
  }, []);  
  
  const capture = () => {  
    const imageSrc = webcamRef.current.getScreenshot();  
    setCapturedImages(prevImages => [...prevImages, imageSrc]);  
  };  
  
  return (  
    <div>  
      <h1>Webcam Capture</h1>  
      <Webcam  
        audio={false}  
        ref={webcamRef}  
        screenshotFormat="image/jpeg"  
      />  
      <div>  
        <h2>Captured Images</h2>  
        {capturedImages.map((image, index) => (  
          <img key={index} src={image} alt={`capture-${index}`} style={{ width: '100px', margin: '10px' }} />  
        ))}  
      </div>  
    </div>  
  );  
};  
  
export default WebcamCapture;  