import React, { useEffect, useState, useRef } from 'react'
import { Collapse, CardContent, Divider, Button, Stack, Avatar, Typography, Box, Backdrop, CircularProgress } from '@mui/material';
import axios from '../Login/axiosConfig'
import AudioPlayer from 'react-h5-audio-player';
import useWindowDimensions from '../Screens/useWindowDimensions.js';
import SeriesCard from '../Components/SeriesCard.js';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const PodcastView = ({
    snackCard,
    mySnacks,
    userId,
    setShowMessages,
    handleScreenChangeClick,


}) => {
    const itemRefs = useRef([]);
    const { height, width } = useWindowDimensions();
    const [currentAudioUrl, setCurrentAudioUrl] = React.useState("")
    const [theSeries, setTheSeries] = useState(null)
    const [isOwner, setIsOwner] = useState(false)
    const [showProgress, setShowProgress] = useState(false)
    const [interviews, setInterviews] = useState(null)
    const [expanded, setExpanded] = React.useState(false);
    const player = useRef()
    const scrollToPlayer = useRef()

 

    useEffect(() => {
        console.log("in Series")
        window.scrollTo(0, 0)
        if (snackCard.userId === userId) {
            setIsOwner(true)
        }
        // getMySeries(snackCard.projectId)
        // getAllSeries(0)

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [snackCard])


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleScrollToItem = () => {
        if (itemRefs.current) {
            itemRefs.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
      
    };

    const getInterviews = (id) => {

        console.log("in getInterviews")
        axios.get(`/api/v1/getInterviews?snackId=${id}`)
            .then(response => {
                console.log("getInterviews response", response.data)
                setInterviews(response.data)
                handleExpandClick()
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log("getInterviews error:", error)
                setExpanded(false)
                setShowMessages({
                    alertType: "error",
                    title: "No Interviews",
                    message: `No interviews recorded for this SnackStory`,
                })
            })
    }



    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>


            <Stack
                direction={"row"}
                paddingX={window.$isMobile ? 2.5 : 4.5}
                paddingTop={2}
                alignItems={"flex-end"}
                justifyContent={"space-between"}>
                <ArrowBackIcon
                    onClick={() => {
                        console.log("HomePage")
                        handleScreenChangeClick({
                            selectedIndex: "HomePage"
                        })
                    }}
                    sx={{
                        fontSize: window.$isMobile ? 14 : 18, cursor: "pointer", color: "#fcfdfd",
                        opacity: 0.3
                    }} />


            </Stack>



            <Box

                paddingTop={5}
                paddingBottom={2.5}

                sx={{
                    // backgroundColor: "#212325",
                    width: "100%",
                    overflowY: "scroll",

                    // height: "80%" 
                }}>


                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >


                    {snackCard && <SeriesCard snackCard={snackCard}
                        isOwner={isOwner}
                        width={width}
                        setShowMessages={setShowMessages}
                        handleScreenChangeClick={handleScreenChangeClick} />}


                </Stack>

                <Stack
                    paddingX={window.$isMobile ? 0 : 15}
                >
                       <div ref={scrollToPlayer} />
                {currentAudioUrl && <Stack
                    marginTop={6}
                    marginBottom={6}
                    width={window.$isMobile ? 350 : 500}

                    alignSelf={"center"}>
                    <AudioPlayer

                        autoPlay
                        ref={player}
                        src={currentAudioUrl}

                        showSkipControls
                        // onClickNext={handleClickNext}
                        // onEnded={handleEnd}
                        // onListen={()=>{
                        //     console.log("time", player.current)
                        // }}
                        onError={(err) => { console.log('play error:', err) }}

                    />
                </Stack>}
                        <Stack
                            paddingTop={0}
                            paddingBottom={5}
                            // ref={(el) => (itemRefs.current[podIndex] = el)}
                            // key={e.interviewId}
                            >

                            <Collapse in={expanded} timeout="auto" unmountOnExit>

                                <CardContent>


                                    <Typography
                                        variant='h5'
                                        sx={{

                                            textAlign: "center",

                                            textTransform: "none",

                                            typography: { fontFamily: "Inter", fontWeight: 500 },

                                            color: "#ffffff"
                                        }} >
                                        Interview Segments
                                    </Typography>
                                    <Divider sx={{ marginBottom: 2, marginTop: 2, backgroundColor: "#ffffff", width: "100%", borderBottomWidth: 10 }} variant="fullWidth" />




                                    {interviews && <Stack
                                        spacing={1}

                                        width={"100%"}
                                        justifyContent={"flex-start"}
                                        direction="row"
                                        useFlexGap flexWrap="wrap">



                                        {interviews.map((e, index) => (
                                            <Stack key={e.interviewId}>
                                                {index > 0 && <Divider sx={{ marginBottom: 2, marginTop: 2, backgroundColor: "#ffffff", width: "100%", borderBottomWidth: 10 }} variant="fullWidth" />}

                                                <Typography
                                                    variant={'h7'}
                                                    marginBottom={2}
                                                    sx={{

                                                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 500 },
                                                        lineHeight: '105%', color: "#ffffff"
                                                    }} fontStyle={"normal"}>

                                                    <br />
                                                    {e.question}
                                                </Typography>
                                                <Typography
                                                    variant='body2'
                                                    sx={{
                                                        textTransform: "none",
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-word",
                                                        typography: { fontFamily: "Inter", fontWeight: 400 },

                                                        lineHeight: "110%",

                                                        color: "#ffffff"
                                                    }} >
                                                    {e.answer ?? ""}
                                                </Typography>

                                         <Typography
                                                       
                                                        variant='body2'
                                                        paddingTop={1}
                                                        onClick={() => {
                                                            console.log(e.audioUrl)
                                                           setCurrentAudioUrl(e.audioUrl)
                                                            if (scrollToPlayer.current) {
                                                                scrollToPlayer.current.scrollIntoView({
                                                                    behavior: 'smooth',
                                                                    block: 'start'
                                                                });
                                                            }
                                                        }}

                                                        width={window.$isMobile ? 300 : 550}
                                                        sx={{
                                                            cursor: "pointer",
                                                            textDecoration: "underline",
                                                            wordWrap: 'break-word',
                                                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 800 },
                                                            lineHeight: '105%', color: "#e8454f", opacity: 1
                                                        }} >
                                                        Play Snippet

                                                    </Typography>


                                            </Stack>

                                        ))}


                                    </Stack>}

                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleExpandClick()
                                            // if (itemRefs.current) {
                                            //     handleScrollToItem(0)
                                            // }
                                        }
                                        }
                                        sx={{ borderColor: "#ffffff", marginTop: 3 }}>
                                        <Typography
                                            variant={'body2'}
                                            sx={{

                                                textTransform: "none",
                                                typography: { fontFamily: "Inter", fontWeight: 500 },

                                                color: "#ffffff"
                                            }} >
                                            Close

                                        </Typography>
                                    </Button>

                                </CardContent>
                            </Collapse>



                            <Stack
                                paddingTop={3}
                                width={"100%"}

                                direction={"row"}
                                justifyContent={window.$isMobile ? "center" : "flex-start"}
                            >
                                <Button
                                    variant="contained"

                                    onClick={() => {
                                        if (!interviews) {
                                            getInterviews(snackCard.snackId)
                                            return
                                        }
                                       // eventually call get interview if no data in interviews
                                        handleExpandClick()
                                    }}

                                    sx={{ backgroundColor: "#2d2d2d", width: "100%" }}>
                                    <Typography
                                        variant={'body1'}
                                        sx={{

                                            textTransform: "none",
                                            typography: { fontFamily: "Inter", fontWeight: 700 },

                                            color: "#ffffff"
                                        }} >
                                        Listen to Original Story

                                    </Typography>
                                </Button>
                            </Stack>

                        </Stack>
                   
                </Stack>


                <Typography
                    variant={'h6'}
                    paddingTop={10}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    All My Snacks
                </Typography>

                <Stack spacing={3}
                    marginBottom={4}
                    marginTop={2}
                    width={width}
                    sx={{
                        maxHeight: 200,
                        overflowX: 'scroll'
                    }}
                    justifyContent={"flex-start"}
                    direction="row"
                    useFlexGap
                // flexWrap="wrap"
                >
                    <Stack justifyContent={"center"}
                        onClick={() => {
                            handleScreenChangeClick({
                                selectedIndex: "INTERVIEW_SNACK",
                                // theSeries: e
                            })
                        }}
                        paddingLeft={2}
                        sx={{ cursor: "pointer" }}
                        maxWidth={window.$isMobile ? 100 : 125}>
                        <Avatar
                            // key={e.seriesId}
                            // key={e.epId}

                            alt="BJM"
                            // src={e.series.imageUrl}
                            // src={e.metaData.podcastImageUrl}
                            sx={{
                                width: window.$isMobile ? 100 : 125,
                                height: window.$isMobile ? 100 : 125,
                                borderRadius: 5,
                                backgroundColor: "#e8454f"
                            }}
                        >
                            <KeyboardVoiceIcon sx={{ fontSize: 50, color: "#fcfdfd", opacity: 0.8 }} />
                        </Avatar>
                        <Typography
                            paddingTop={1}
                            sx={{
                                fontSize: 12,
                                textAlign: "center",
                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 500 },
                                opacity: 0.8,
                                color: "#ffffff"
                            }} >
                            Interview

                        </Typography>

                    </Stack>

                    {mySnacks && mySnacks.map((e) => (
                        <Stack justifyContent={"center"}
                            key={e.snackId}
                            alignItems={"center"}
                            onClick={() => {
                                handleScreenChangeClick({
                                    selectedIndex: `PODCAST_SNACK-View`,
                                    snackCard: e
                                })
                            }}
                            paddingLeft={2}
                            sx={{ cursor: "pointer" }}
                            maxWidth={window.$isMobile ? 100 : 150}>
                            <Avatar

                                // key={e.epId}

                                alt="BJM"
                                src={e.imageUrl}
                                // src={e.metaData.podcastImageUrl}
                                sx={{
                                    width: window.$isMobile ? 100 : 125,
                                    height: window.$isMobile ? 100 : 125,
                                    borderRadius: 5,
                                }}
                            />
                            <Typography
                                // variant={'body2'}
                                paddingTop={1}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                    fontSize: 12,
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    opacity: 0.8,
                                    color: "#ffffff"
                                }} >
                                {e.title}

                            </Typography>
                        </Stack>
                    ))}
                </Stack>




            </Box>

        </div >
    )

}

export default PodcastView
