import React, { useEffect, useState, useRef } from 'react'

import { Stack, Typography, Box, Backdrop, CircularProgress } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SeriesCard from '../Components/SeriesCard';
import placeholder from '../assets/images/snack-story-hero.png'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { keyframes } from '@mui/system';
import Footer from '../Components/Footer';

const pulsate = keyframes`  
  0% {  
    transform: scale(1);  
    opacity: 1;  
  }  
  50% {  
    transform: scale(1.25);  
    opacity: 0.8;  
  }  
  100% {  
    transform: scale(1);  
    opacity: 1;  
  }  
`;

const HomePage = ({
    openLogin,
    getSnackMediaPublic,
    getSnackMediaPrivate,
    snackMediaPublic,
    snackMediaPrivate,
    setShowMessages,
    handleScreenChangeClick,
    isAuthenticated

}) => {
    const { height, width } = useWindowDimensions();
    const [feedIndex, setFeedIndex] = useState(0);
    const [showProgress, setShowProgress] = useState(false)



    useEffect(() => {
        console.log("in messenger")
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (!snackMediaPrivate) {
            getSnackMediaPrivate()
        }
    }, [isAuthenticated])


    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>

            {/* Intro */}
            <Stack
                paddingX={window.$isMobile ? 2.5 : 7.5}
                paddingTop={window.$isMobile ? 5 : 10}
                paddingBottom={window.$isMobile ? 5 : 5}

                alignItems={"center"}
                spacing={window.$isMobile ? 0 : 2}

                direction={window.$isMobile ? "column" : "row"}
                width={"100%"}
                justifyContent={"center"} >

                <Stack spacing={0} direction={"column"} width={window.$isMobile ? "100%" : "100%"}>

                    {window.$isMobile ?
                        <Typography
                            variant={'h1'}
                            paddingBottom={2}

                            sx={{

                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                                lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                            }} fontStyle={"normal"}>

                            Pass Down Your <span style={{ color: '#66a8da' }}>Stories</span>
                            {/* Discover <br /><span style={{ color: '#66a8da' }}> Your Stories</span><br />with DataSnack */}
                        </Typography> :
                        <Typography
                            variant={window.$isMobile ? 'h2' : 'h2'}
                            paddingBottom={2}

                            sx={{

                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                                lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                            }} fontStyle={"normal"}>

                            Pass Down Your Stories with <span style={{ color: '#66a8da' }}>{window.$isMobile && <br />}SnackStories</span>
                            {/* Discover <br /><span style={{ color: '#66a8da' }}> Your Stories</span><br />with DataSnack */}
                        </Typography>
                    }



                    <Typography
                        variant={window.$isMobile ? 'h6' : 'h6'}
                        paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 600 : 600 },
                            lineHeight: '105%', color: "#ffffff"
                        }} fontStyle={"normal"}>

                        Animated Video Tales in Your Voice
                    </Typography>

                    {!window.$isMobile && <Typography
                        variant={'body1'}
                        paddingBottom={window.$isMobile ? 4 : 4} paddingRight={window.$isMobile ? "10%" : "15%"}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 400 : 400 },
                            lineHeight: '120%', color: "#EBEBEB"
                        }} fontStyle={"normal"}>
                        Welcome to SnackStories, where your timeless tales come to life through animated video stories. Our AI-powered interviewer captures your cherished memories and personal experiences, transforming them into beautifully crafted tales. What makes SnackStories unique is our ability to clone your voice, ensuring your stories are told just the way you would narrate them. Share these animated stories with your kids and grandkids, and let your legacy be treasured for generations to come. Don’t wait—start creating your SnackStory today and keep your memories alive for your family.

                    </Typography>}

                    <Stack
                        direction={window.$isMobile ? 'column' : 'row'}
                        spacing={2}
                        paddingTop={window.$isMobile ? 2 : 0}
                        alignItems={"center"}
                        justifyContent={window.$isMobile ? "center" : "flex-start"}
                        onClick={() => {
                            handleScreenChangeClick({
                                selectedIndex: "INTERVIEW_SNACK",
                                // theSeries: e
                            })
                        }}

                        sx={{ cursor: "pointer" }}
                    // maxWidth={window.$isMobile ? 100 : 125}
                    >


                        <KeyboardVoiceIcon

                            sx={{
                                fontSize: window.$isMobile ? 100 : 50,
                                animation: `${pulsate} 1.5s infinite`,
                                color: "#fcfdfd", opacity: 0.8
                            }} />

                        <Typography
                            variant={window.$isMobile ? 'h5' : 'h6'}

                            sx={{

                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 600 : 600 },
                                lineHeight: '105%', color: "#ffffff"
                            }} fontStyle={"normal"}>

                            Try It Now
                        </Typography>



                    </Stack>

                </Stack>

                {!window.$isMobile && <Stack width={"100%"} direction={"width"} justifyContent={"flex-end"}>
                    <img src={placeholder} alt="Location Image"
                        style={{
                            width: "80%", objectFit: "cover",
                            display: "block",
                            // width: window.$isMobile ? 100 : 175,
                            // height: window.$isMobile ? 100 : 175,
                            borderRadius: 5,

                        }} />
                </Stack>}

            </Stack>


            <Box
                paddingY={2.5}

                sx={{
                    width: "100%",
                    overflowY: "scroll",
                }}>


                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >



                    {snackMediaPublic && <InfiniteScroll
                        dataLength={snackMediaPublic.length} //This is important field to render the next data
                        next={() => {
                            getSnackMediaPublic(feedIndex + 1)
                            setFeedIndex(feedIndex + 1)
                        }}
                        hasMore={true}
                        loader={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }} >

                            </Typography>

                        }
                        refreshFunction={() => {
                            console.log("in refresh")
                            getSnackMediaPublic(0)
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={40}
                        pullDownToRefreshContent={
                            <Typography
                                paddingY={3}
                                variant='h5'
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Pull down to refresh
                            </Typography>

                        }
                        releaseToRefreshContent={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Release to refresh
                            </Typography>
                        }
                    >
                        <Stack direction={"column"} spacing={5}
                            marginTop={2}
                            // useFlexGap flexWrap="wrap"
                            justifyContent={"center"} width={"100%"}>
                            {snackMediaPublic && snackMediaPublic.map((e, index) => (
                                <React.Fragment key={e.snackId}>
                                    <Stack


                                        spacing={5} direction={"column"} width={"100%"}>
                                        <SeriesCard snackCard={e}
                                            width={width}
                                            setShowMessages={setShowMessages}
                                            handleScreenChangeClick={handleScreenChangeClick} />




                                    </Stack>

                        
                                </React.Fragment>
                            ))}

                        </Stack>
                    </InfiniteScroll>
                    }
                </Stack>


            </Box>
            <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />

        </div >
    )

}

export default HomePage

