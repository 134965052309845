import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'

import { Stack, Avatar, InputAdornment, Typography, CircularProgress, IconButton, TextField, Button, Box, } from '@mui/material';
import SendIcon from '@mui/icons-material/Send'
import useWindowDimensions from '../Screens/useWindowDimensions.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const mobileWidth = .90
const desktopWidth = 600

const MiniPodcast = ({
    isAuthenticated,
    openLogin,
    setShowMessages,
    handleScreenChangeClick,
    topPadding

}) => {

    const inputRef = useRef(null)
    const scrollRef = useRef(null);
    const [userInput, setUserInput] = useState("")
    const [shouldClearText, setShouldClearText] = useState(false)
    const { height, width } = useWindowDimensions();
    const [showProgress, setShowProgress] = useState(false)



    useEffect(() => {
        console.log("in messenger")
        window.scrollTo(0, 0)


    }, [])


    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // Do something with the message
            console.log("enter was pressed");
            handleCustomPodcast(userInput, "userMsg")

        }
    };


    const handleCustomPodcast = (newMessage, requestType) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Account Required to Create Mini-Podcasts",
                message: `Please log in or create an account to continue.`,
            })
            openLogin()
            return
        }


        if (newMessage.length < 5) {
            setShowMessages({
                alertType: "error",
                title: "Content Description Too Short",
                message: `Please provide a more detailed topic description."`,
            })
            return
        }

        setShowMessages({
            alertType: "success",
            title: "Creating Custom Mini-Podcast...",
            message: "Processing usually takes about 2-3 minutes. We will update your feed as soon as the mini-podcast is ready.",
        })
        setShowProgress(true)

        ///
        axios.post("/api/v1/learnTopicGenerator", [
            {
                "topic": newMessage
            }

        ])
            .then(response => {
                setShowProgress(false)
                setShowMessages(null)
                console.log("got script from script:", response.data)

                handleScreenChangeClick({
                    selectedIndex: "PODCAST_SNACK-View",
                    snackCard: response.data
                })
                setUserInput("")
                setTimeout(() => {
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView({ behaviour: "smooth" });
                    }
                }, 2500)

            }).catch(error => {
                setShowProgress(false)
                console.log("error from audio", error)
                setShowMessages({
                    alertType: "error",
                    title: "Problem Creating Mini-Podcast",
                    message: "Please try again shortly.",
                })
            })

    }



    return (
        <Box style={{
            backgroundImage: `linear-gradient(to bottom, #212325 50%, #0f0f0f 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: height,


        }}>
            {/* Back button */}
            <Stack
                direction={"row"}
                paddingX={window.$isMobile ? 2.5 : 5}
                paddingTop={2}
                // alignItems={"flex-end"}
                justifyContent={"flex-start"}>
                <ArrowBackIcon
                    onClick={() => {
                        console.log("HomePage")
                        handleScreenChangeClick({
                            selectedIndex: "HomePage"
                        })
                    }}
                    sx={{
                        fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                        opacity: 0.3
                    }} />


            </Stack>
            {/* Content Block */}
            <Stack

                paddingX={window.$isMobile ? 5 : "20%"}

                style={{
                    display: "flex",
                    minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: -80,
                    width: "100%"
                }}
                direction="column"
                justifyContent={"center"} alignItems={"flex-start"}>
                <Typography
                    paddingBottom={2}
                    variant={'h2'}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Mini-Podcast
                </Typography>
                <Typography
                    variant={'body1'}
                    paddingBottom={window.$isMobile ? 4 : 4}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 400 : 400 },
                        lineHeight: '120%', color: "#EBEBEB"
                    }} fontStyle={"normal"}>
                    Unlock your creativity with DataSnack's mini-podcast feature! Simply enter a topic, and our AI will generate a captivating 4 to 5-minute podcast for you. Whether it's for entertainment, education, or news, DataSnack makes podcasting effortless.

                </Typography>

                <TextField
                    ref={inputRef}
                    size={"small"}
                    focused={true}
                    variant={'outlined'}
                    margin={"none"}
                    multiline
                    maxRows={8}
                    onFocus={() => {

                        if (shouldClearText) {
                            setUserInput("")
                            setShouldClearText(false)

                        }
                    }}
                    sx={{
                        width: window.$isMobile ? "90%" : "90%",
                        '& label.Mui-focused': {
                            color: '#ffffff',

                        },
                        '& .MuiFormLabel-root': {
                            color: '#ffffff',
                            opacity: 0.9,
                        },
                        '& .MuiInputBase-root': {
                            color: "#ffffff7f",
                            borderRadius: 1,
                            typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
                            '& .MuiOutlinedInput-root': {
                                color: "#ffffff",

                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#006ec2",
                            borderWidth: "1px"
                        },
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#006ec2",
                                borderWidth: "1px"
                            }
                        }


                    }}

                    id="searchInput"
                    type={"text"}
                    onChange={(e) => {

                        setUserInput(e.target.value)
                    }}
                    onKeyDown={handleKeyDown}

                    value={userInput}

                    InputProps={{

                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="submit"



                                    onClick={() => {


                                        handleCustomPodcast(userInput, "userMsg")

                                    }}
                                    edge="end"
                                >
                                    {userInput === "" ? null : showProgress ? <CircularProgress size={10} style={{ color: '#ffffff' }} /> : <SendIcon sx={{ fontSize: 15, color: "#ffffff", opacity: 0.8 }} />}
                                </IconButton>

                            </InputAdornment>

                    }}

                    label={`Add Topic to Create New Mini-Podcast Series`}
                />


            </Stack>

        </Box>



    )

}

export default MiniPodcast
