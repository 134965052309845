import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { keyframes } from '@mui/system';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { Stack, Typography, Button } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

const pulsate = keyframes`  
  0% {  
    transform: scale(1);  
    opacity: 1;  
  }  
  50% {  
    transform: scale(1.25);  
    opacity: 0.8;  
  }  
  100% {  
    transform: scale(1);  
    opacity: 1;  
  }  
`;


const RecordButton = ({
    isAuthenticated,
    openLogin,
    setShowMessages,
    handleScreenChangeClick,
    topPadding

}) => {


    const clientRef = useRef(null)
    const wavRecorderRef = useRef(null)
    const wavStreamPlayerRef = useRef(null)
    const [recordClicked, setRecordClicked] = useState(false)
    const startTimeRef = useRef(new Date().toISOString());
    const [sessionCreatedSuccess, setSessionCreatedSuccess] = useState(false)
    const [statusMsg, setStatusMsg] = useState("Start Recording")
    const [snackId, setSnackId] = useState(null)
    const checkIndex = useRef(0)
    const [transcript, setTranscript] = useState("")
    const audioId = useRef("")

    const handleRecordButton = () => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Setup a SnackStories Account",
                message: `Please log in or create an account to continue.`,
            })
            openLogin()
            return
        }

        switch (recordClicked) {
            case true:

                disconnectConversation()
                setRecordClicked(false)
                // getSnackCard()
                break;
            case false:
                startSession()
                setRecordClicked(true)
                checkIndex.current = 0
                // start session initiates use effect that triggers conversation to start
                break;
        }
    }

    const createAudioStory = ()=> {
         setShowMessages({
            alertType: "success",
            title: "Creating SnackStory...",
            message: `Processing will take between 3 to 5 minutes, and we will notify you via email once it's complete.`,
        })
        setStatusMsg("Creating SnackStory...")
        axios.post(`/api/v1/createAudioStory`,{
            snackId: snackId
        })
        .then(response => {
            console.log("test", response.data)
           
            handleScreenChangeClick({
                selectedIndex: "PODCAST_SNACK-View",
                snackCard: response.data
            })

        }).catch(error => {
            // need to figure out way to handle user swiping away from screen and waiting
            console.log("getSnackCard error:", error)
            checkIndex.current = 0
        })
    }

    const getSnackCard = () => {
        console.log("in getSnackCard", checkIndex.current)
        setTimeout(() => {
            axios.get(`/api/v1/getSnackCard?q=${snackId}`)
                .then(response => {
                    console.log("getSnackCard", response.data)
                    if (!response.data.snackId) {
                        console.log("check index", checkIndex.current)
                        if (checkIndex.current >= 10) {
                            return
                        }
                        checkIndex.current++
                        getSnackCard()
                        return
                    }
                    checkIndex.current = 0
                    handleScreenChangeClick({
                        selectedIndex: "PODCAST_SNACK-View",
                        snackCard: response.data
                    })

                }).catch(error => {
                    // need to figure out way to handle user swiping away from screen and waiting
                    console.log("getSnackCard error:", error)
                    checkIndex.current = 0
                })
        }, 15 * 1000)
    }

    useEffect(() => {

        if (sessionCreatedSuccess) {
            connectConversation()
        }

    }, [sessionCreatedSuccess])


    const startSession = () => {

        wavStreamPlayerRef.current = new WavStreamPlayer({ sampleRate: 24000 })
        let myToken = localStorage.getItem('token');
        clientRef.current = new WebSocket(`${window.$ip}/api/v1/interview?token=${myToken}`)
        const client = clientRef.current
        console.log("client", client)


        client.onopen = () => {
            client.send(JSON.stringify({
                type: "intro",
                data: "Hi from the client"
            }));
        };

  
        client.onmessage = (async (e) => {
            console.log("raw e", e)
           

            const obj = JSON.parse(e.data)
            console.log("obj from server:", obj)
            switch (obj.type) {
                // assistant question
                case 'response.audio_transcript.done':
                    console.log("response.audio_transcript.done", obj);
                   
                   
                    break;

                    case "response.content_part.done":
                        console.log("response.content_part.done", obj);  
                        setTranscript(obj.content.transcript)


                case "snack.id":
                    console.log("snackId", obj);
                    if (obj.snackId) {
                        setSnackId(obj.snackId)
                    }
                    break;


                case 'session.created':
                    console.log("session.created", obj);
                    setSessionCreatedSuccess(true)
                    setStatusMsg("Session Created")
                    break;

                case 'input_audio_buffer.speech_started':
                   
                    break;

                case 'response.audio.delta':
                    console.log("response.audio.delta:", obj);

                    if (obj.delta) {
                        if (obj.item_id) {
                            audioId.current = obj.item_id
                            console.log("audioId", audioId.current)
                        }
                        console.log("adding audio to buffer")
                        // const pcmData = WavPacker.floatTo16BitPCM(obj.delta)
                        const binary = atob(obj.delta);
                        const bytes = Uint8Array.from(binary, (c) => c.charCodeAt(0));
                        const pcmData = new Int16Array(bytes.buffer);
                        try {

                            wavStreamPlayerRef.current.add16BitPCM(pcmData);


                        } catch (error) {
                            console.log("wavStreamPlayer:", error)
                        }

                    }

                    break;

                default:
                    console.log("default:", obj)
            }

        })

        client.onerror = ((e) => {
            console.log("on error", e)
        })

        setStatusMsg("Starting...")
    }

    const connectConversation = async () => {

        const client = clientRef.current
        console.log("client", client)
        await wavStreamPlayerRef.current.connect();
        wavRecorderRef.current = new WavRecorder({ sampleRate: 24000 })
        const wavRecorder = wavRecorderRef.current


        // Set state variables
        startTimeRef.current = new Date().toISOString();

        // Connect to microphone
        await wavRecorder.begin();

        await wavRecorder.record((data) => {
            client.send(data.mono)


        });
        setStatusMsg("Recording")
        setTimeout(() => {
            setStatusMsg("Begin Story")
        }, [3 * 1000])

        setTimeout(() => {
            setStatusMsg("Click to Stop Recording")
        }, [13 * 1000])

    };

    const disconnectConversation = async () => {
      
        setSessionCreatedSuccess(false)
        setStatusMsg("Disconnecting")
        const client = clientRef.current;
        client.close()

        const wavRecorder = wavRecorderRef.current;
        try {
            await wavRecorder.end();
        } catch (error) {
            console.log("recorder stop error:", error)
            setRecordClicked(false)
        }


        const wavStreamPlayer = wavStreamPlayerRef.current;
        try {
            await wavStreamPlayer.interrupt();
        } catch (error) {
            console.log("player stop:", error)
            setRecordClicked(false)
        }
        setShowMessages({
            alertType: "success",
            title: "Finished Recording",
            message: `Click "Create Story" to generate an illustrated and slightly embellished children's story in your own unique voice. Processing will take between 3 to 5 minutes, and we will notify you via email once it's complete.`,
        })
        setStatusMsg("Re-Record Story")
       

    };

    return (

        <Stack width={"100%"} alignItems={"center"} >
            <Stack
                direction={'column'}
                spacing={2}
                paddingTop={window.$isMobile ? 2 : 0}
                alignItems={"center"}
                justifyContent={window.$isMobile ? "center" : "flex-start"}
                onClick={handleRecordButton}

                sx={{ cursor: "pointer" }}
            // maxWidth={window.$isMobile ? 100 : 125}
            >


                <KeyboardVoiceIcon

                    sx={{
                        fontSize: 100,
                        animation: sessionCreatedSuccess ? `${pulsate} 1.5s infinite` : ``,
                        color: "#fcfdfd", opacity: 0.8
                    }} />

                <Typography
                    variant={window.$isMobile ? 'h6' : 'h5'}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '120%', color: "#fcfdfd"
                    }} fontStyle={"normal"}>
                    {statusMsg}

                </Typography>
                {/* <Typography
                    variant={window.$isMobile ? 'h6' : 'h5'}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '120%', color: "#fcfdfd"
                    }} fontStyle={"normal"}>
                    {transcript}

                </Typography> */}

            </Stack>
            <Button 
            sx={{color: "#ffffff"}}
            onClick={createAudioStory}>
                Create Audio Story
            </Button>

        
        </Stack>


    )

}



export default RecordButton