import React, { useEffect, useState, useRef } from 'react'

import { Stack, Typography, Box, Backdrop, CircularProgress } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SeriesCard from '../Components/SeriesCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { keyframes } from '@mui/system';

const pulsate = keyframes`  
  0% {  
    transform: scale(1);  
    opacity: 1;  
  }  
  50% {  
    transform: scale(1.25);  
    opacity: 0.8;  
  }  
  100% {  
    transform: scale(1);  
    opacity: 1;  
  }  
`;

const MySeries = ({
    // allSeries,
    openLogin,
    getMySeries,
    mySeries,
    // getAllSeries,
    getAllMySeries,
    setShowMessages,
    handleScreenChangeClick,
    isAuthenticated

}) => {
    const { height, width } = useWindowDimensions();
    const [feedIndex, setFeedIndex] = useState(0);
    const [showProgress, setShowProgress] = useState(false)



    useEffect(() => {
        console.log("in messenger")
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (!mySeries) {
            getMySeries()
        }
    }, [isAuthenticated])







    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>



            <Box
                paddingY={2.5}

                sx={{
                    width: "100%",
                    overflowY: "scroll",
                }}>
                <Stack
                    direction={"row"}
                    paddingX={window.$isMobile ? 2.5 : 5}
                    paddingTop={2}
                    // alignItems={"flex-end"}
                    justifyContent={"flex-start"}>
                    <ArrowBackIcon
                        onClick={() => {
                            console.log("HomePage")
                            handleScreenChangeClick({
                                selectedIndex: "HomePage"
                            })
                        }}
                        sx={{
                            fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                            opacity: 0.3
                        }} />


                </Stack>

                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >
                    <Typography
                        variant={window.$isMobile ? 'h5' : 'h3'}
                        paddingY={window.$isMobile ? 0 : 3}
                        paddingX={window.$isMobile ? 5 : 0}
                        sx={{
                            textAlign: "center",
                            textTransform: "none",
                            typography: { fontFamily: "Inter", fontWeight: 200 },
                            color: "#ffffff7f"
                        }} >
                        My SnackStories
                    </Typography>



                    {mySeries && <InfiniteScroll
                        dataLength={mySeries.length} //This is important field to render the next data
                        next={() => {
                            getAllMySeries(feedIndex + 1)
                            setFeedIndex(feedIndex + 1)
                        }}
                        hasMore={true}
                        loader={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }} >

                            </Typography>

                        }
                        refreshFunction={() => {
                            console.log("in refresh")
                            getAllMySeries(0)
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={40}
                        pullDownToRefreshContent={
                            <Typography
                                paddingY={3}
                                variant='h5'
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Pull down to refresh
                            </Typography>

                        }
                        releaseToRefreshContent={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Release to refresh
                            </Typography>
                        }
                    >
                        <Stack direction={"column"} spacing={5}
                            marginTop={2}
                            // useFlexGap flexWrap="wrap"
                            justifyContent={"center"} width={"100%"}>
                            {mySeries && mySeries.map((e, index) => (
                                <React.Fragment key={e.snackId}>
                                    <Stack 


                                        spacing={5} direction={"column"} width={"100%"}>
                                        <SeriesCard s={e.dataMap} snackCard={e}
                                            width={width}
                                            setShowMessages={setShowMessages}
                                            handleScreenChangeClick={handleScreenChangeClick} />




                                    </Stack>

                                    <Stack
                                        direction={window.$isMobile ? 'column' : 'row'}
                                        spacing={2}
                                       
                                        paddingBottom={5}
                                        alignItems={"center"}
                                        justifyContent={window.$isMobile ? "center" : "center"}
                                        onClick={() => {
                                            handleScreenChangeClick({
                                                selectedIndex: "INTERVIEW_SNACK",
                                                // theSeries: e
                                            })
                                        }}

                                        sx={{ cursor: "pointer" }}
                                    // maxWidth={window.$isMobile ? 100 : 125}
                                    >


                                        <KeyboardVoiceIcon

                                            sx={{
                                                fontSize: window.$isMobile ? 50 : 50,
                                                animation: `${pulsate} 1.5s infinite`,
                                               
                                                color: "#fcfdfd",
                                                 opacity: 0.6
                                            }} />

                                        



                                    </Stack>
                                </React.Fragment>
                            ))}

                        </Stack>
                    </InfiniteScroll>
                    }
                </Stack>


            </Box>

        </div >
    )

}

export default MySeries

