import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import {
    Button, Box, Divider, Link, Stack, Chip, Typography, CardContent, Collapse
} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import AudioPlayer from 'react-h5-audio-player';
import '../Components/audioNewStyle.css'
import BotCard from '../SnackViews/GameView.js';
import SocialShare from '../Components/SocialShare.js'
import ReactPlayer from 'react-player'



export default function SeriesCard({
    snackCard,
    isOwner,
    handleScreenChangeClick,
    setShowMessages,
    width
}) {

    const itemRefs = useRef([]);

    const [expanded, setExpanded] = React.useState(false);
    const [epIndex, setEpIndex] = React.useState(0)
    const player = useRef()
    const scrollToPlayer = useRef()
    const [playlist, setPlaylist] = React.useState(null)
    const [currentVideoUrl, setCurrentVideoUrl] = React.useState(null)
    const [currentTrack, setTrackIndex] = React.useState(0)
    const [showTutorial, setShowTutorial] = React.useState(false)
    const checkIndex = useRef(0)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleScrollToItem = (index) => {
        itemRefs.current[index].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };



    const handleCreateVideo = () => {
        setShowMessages({
            alertType: "success",
            title: "Creating SnackStory Video...",
            message: `We'll update your SnackStory video when finished processing.`,
        })
        axios.post(`/api/v1/createVideo`, {
            snackId: snackCard.snackId,
            style: "KIDS"
        })
            .then(() => {

                const handle = setInterval(() => {
                    axios.get(`/api/v1/getSnackCard?q=${snackCard.snackId}`)
                        .then(response => {
                            console.log("getSnackCard", response.data)
                            if (!response.data.videoFilename) {
                                console.log("check index", checkIndex.current)
                                if (checkIndex.current >= 10) {
                                    clearInterval(handle)
                                }
                                checkIndex.current++
                                return
                            }
                            clearInterval(handle)
                            checkIndex.current = 0
                          

                        }).catch(error => {
                            // need to figure out way to handle user swiping away from screen and waiting
                            console.log("getSnackCard error:", error)
                            checkIndex.current = 0
                            clearInterval(handle)
                        })
                }, 45 * 1000)


            }).catch(error => {

                console.log("error from video", error)
                setShowMessages({
                    alertType: "error",
                    title: `SnackStory Video Processing Issue`,
                    message: "Problem creating the SnackStory video. Please try again later.",
                })
            })
    }

    const handlePrivacy = (snackId) => {
        axios.post(`/api/v1/updatePrivacy?snackId=${snackId}`, {})
            .then(() => {

                handleScreenChangeClick({
                    updateDash: true
                })
                snackCard.isPrivate = !snackCard.isPrivate
                setShowMessages({
                    alertType: "success",
                    title: "Success!",
                    message: `Series set to ${snackCard.isPrivate ? "private" : "public"}`,
                })
               

            }).catch(error => {

                console.log("error from audio", error)
                setShowMessages({
                    alertType: "error",
                    title: `Problem setting series to ${snackCard.isPrivate ? "public" : "private"}. Please try again later.`,
                    message: "",
                })
            })
    }

    return (
        <Box >
            <Stack
                width={"100%"}
                paddingX={1.5}
            >

                <Stack sx={{
                    position: "relative",
                    display: "inline-block"
                }}>
                    <img

                        src={snackCard.imageUrl} alt="Location Image"
                        style={{ width: "100%", objectFit: "cover", maxHeight: 350, borderRadius: 10 }} />
                    {/* <Button
                        sx={{

                            position: `absolute`,
                            top: `50%`,
                            left: `50%`,
                            transform: `translate(-50%, -50%)`,
                            padding: `10px 20px`,
                            fontSize: `16px`,
                            cursor: `pointer`,

                        }}
                        onClick={() => {
                            getAudioLink(s.pods[0].epId, "single")
                            if (scrollToPlayer.current) {
                                scrollToPlayer.current.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                });
                            }

                        }}

                    >
                        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                            <PlayCircleFilledWhiteIcon sx={{
                                color: "#e8454f", fontSize: 75,
                                opacity: 0.9


                            }} />
                            <Typography
                                paddingTop={1}
                                variant={window.$isMobile ? 'h6' : 'h6'}
                                paddingBottom={window.$isMobile ? 4 : 4}
                                sx={{

                                    textAlign: "center", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 600 : 600 },
                                    lineHeight: '105%', color: "#ffffff"
                                }} fontStyle={"normal"}>

                                Listen to SnackStory
                            </Typography>

                        </Stack>
                    </Button> */}


                </Stack>
                <Stack paddingTop={1} paddingRight={1} direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    {isOwner && <Typography

                        // paddingTop={3}
                        onClick={() => {
                            console.log("isPublic", snackCard.isPublic)
                            handlePrivacy(snackCard.snackId)



                        }}
                        sx={{
                            fontSize: window.$isMobile ? 14 : 14, cursor: "pointer", color: "#fcfdfd",
                            opacity: 0.3,
                            textDecoration: "underline"
                        }} fontStyle={"normal"}>
                        {`Make ${snackCard.isPrivate ? "public" : "private"}`}
                    </Typography>}

                    <SocialShare snackCard={snackCard} buttonColor={"#fcfdfd4c"} />

                </Stack>

                <Typography
                    paddingTop={3}

                    variant={'h4'}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {snackCard.title}
                </Typography>

                <Typography
                    variant={'body1'}
                    paddingTop={1}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 600 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {snackCard.subtitle}
                </Typography>
                <Typography
                    variant={'body1'}
                    paddingTop={2}
                    paddingX={window.$isMobile ? 0 : 20}

                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,

                        whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                        lineHeight: '100%', color: "#ebebeb"
                    }} fontStyle={"normal"}>
                    {snackCard.description}
                </Typography>
            


                <Typography
                    variant={'h6'}
                    paddingTop={6}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Highlights
                </Typography>

                <Stack spacing={1}
                    paddingTop={3}
                    marginBottom={window.$isMobile ? 0 : 4}
                    width={"100%"}
                    sx={{ maxHeight: 600, overflowX: 'scroll' }}
                    justifyContent={"center"}
                    direction="row"
                    useFlexGap flexWrap="wrap">
                    {snackCard && snackCard.topics.map((e) => (
                        <Chip
                            variant={'filled'}
                            key={e}
                            label={e}



                            sx={{
                                backgroundColor: '#2d2d2d',
                                padding: 1,
                                fontSize: 16,
                                maxWidth: width * .8,
                                typography: { fontFamily: "Inter" },
                                textTransform: "none", color: "#ffffff",

                            }}
                            onClick={() => {
                                console.log("clicked chip")

                            }} />
                    ))}
                </Stack>

                {snackCard.videoUrl && <Stack direction={"row"}
                    justifyContent={"center"} width={"100%"}
                    marginTop={window.$isMobile ? 5 : 0}
                    marginBottom={window.$isMobile ? 2 : 2}
                    sx={{ position: "relative", paddingTop: "56.25%" }}
                >
                    <ReactPlayer
                        style={{
                            position: "absolute", top: 0, left: 0,
                        }}
                        controls={true}
                        playbackRate={1.2}
                        width={"100%"}
                        height={"100%"}
                        url={snackCard.videoUrl}
                        onError={(e)=>{
                            console.log("player error:", e)
                           
                        }}
                        config={{
                            file: {
                                attributes: {
                                    crossOrigin: "true",
                                  },
                              tracks: [
                                {
                                  kind: 'subtitles',
                                  src: snackCard.videoSubtitleUrl, // URL to your subtitle file
                                  srcLang: 'en',
                                  label: 'English', 
                                  default: true // Set as default if needed
                                },
                                // Add more subtitle tracks if you have them
                              ]
                            }
                          }}
                        
                        />
                       
                </Stack>}


                {isOwner && !snackCard.videoUrl && <Stack
                    paddingTop={3}
                    width={"100%"}
                    paddingX={window.$isMobile ? 0 : 15}
                    direction={"row"}
                    justifyContent={window.$isMobile ? "center" : "flex-start"}
                >
                    <Button
                        variant="contained"

                        onClick={handleCreateVideo}

                        sx={{ backgroundColor: "#e8454f", width: "100%" }}>
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Create Video

                        </Typography>
                    </Button>
                </Stack>}
            </Stack >
        </Box >
    );
}