import * as React from 'react';
import { CssBaseline, AppBar, Drawer, Toolbar, List, ListItemButton, ListItemText, Dialog, DialogContent, Button, Divider, DialogActions, DialogTitle, Typography, Box } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShowMessages from '../Components/ShowMessages';
import Header from '../Components/Header';
import { useState, useEffect } from 'react'
import axios from '../Login/axiosConfig'
import ListSubheader from '@mui/material/ListSubheader';
import LogoutIcon from '@mui/icons-material/Logout';

import "../scss/fonts/Courier_Prime/courier.css"

import { useNavigate, useSearchParams } from "react-router-dom";
import Feedback from './Feedback';
import Contact from './Contact';
import FeedbackIcon from '@mui/icons-material/Feedback';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Privacy from './Privacy';
import TOS from './TOS';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ChatIcon from '@mui/icons-material/Chat';

import PodcastsIcon from '@mui/icons-material/Podcasts';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlightIcon from '@mui/icons-material/Flight';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';


import PodcastView from '../SnackViews/PodcastView.js';


import HomePage from './HomePage.js';
import MiniPodcast from '../Snacks/MiniPodcast'

import Interview from '../Snacks/Interview.js';
import MySeries from './MySeries.js';


const drawerWidth = 230;



export default function DashContainer({ userFirebase, goToPage, signOut, isAuthenticated, isEmailVerified, setIsAuthenticated, ui }) {
  // console.log("userFirebase, isAuthenticated, isEmailVerified, ", userFirebase, isAuthenticated, isEmailVerified)
  const [sidebar, setSidebar] = useState("HomePage")
  const [emailOpen, setEmailOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [deepDive, setDeepDive] = useState({});
  const [showMessages, setShowMessages] = useState(null);
  const [updater, setUpdater] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [apiKey, setApiKey] = useState(null)
  const [snackMediaPublic, setSnackMediaPublic] = useState(null)
  const [snackMediaPrivate, setSnackMediaPrivate] = useState(null)
  const [snackCard, setSnackCard] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const shareQuery = searchParams.get('q');


  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log("dash container use effect")
    if (isAuthenticated) {
      getCurrentUser()
      getSnackMediaPrivate(0)
      // setSidebar(sidebar === "HomePage" ? "MySeries" : sidebar)
    }

  }, [isAuthenticated])

  useEffect(() => {
    console.log("gotopage:", goToPage)
    if (goToPage !== undefined) {
      setSidebar(goToPage)
    }

    if (shareQuery) {
      getSnackMediaShared(shareQuery)
    } else {
      getSnackMediaPublic(0)
    }

  }, [])


  const getSnackMediaPublic = (page) => {

    console.log("in getSnackMediaPublic")
    axios.get(`/api/v1/getSnackMediaPublic?page=${page}`)
      .then(response => {
        console.log("getSnackMediaPublic response", response.data)
        console.log("page in get all deep dive", page)
        // const finalArray = addIconToSnackArray(response.data)
        if (!page) {
          setSnackMediaPublic(response.data)
        } else {
          setSnackMediaPublic([...snackMediaPublic, ...response.data])
        }

      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log("getSnackMediaPublic error:", error)
      })
  }

  const getSnackMediaPrivate = (page) => {

    console.log("in getSnackMedia")
    axios.get(`/api/v1/getSnackMedia?page=${page}`)
      .then(response => {
        console.log("getSnackMedia response", response.data)
        console.log("page in get all deep dive", page)
        // const finalArray = addIconToSnackArray(response.data)
        if (!page) {
          setSnackMediaPrivate(response.data)
        } else {
          setSnackMediaPrivate([...snackMediaPrivate, ...response.data])
        }

      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log("getSnackMedia error:", error)
      })
  }


  const getSnackMediaShared = (id) => {

    console.log("in getSnackCard")
    axios.get(`/api/v1/getSnackMediaShared?q=${id}`)
      .then(response => {
        console.log("getSnackMediaShared response", response.data)
        setSnackCard(response.data)
        getSnackMediaPublic(0)
        setSidebar("PODCAST_SNACK-View")

      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log("getSnackCard error:", error)
      })
  }



  const addIconToSnackArray = (dataArray) => {
    const result = dataArray.map((e) => {
      switch (e.snackType) {
        case "PODCAST_SNACK":
          e.icon = <PodcastsIcon sx={{
            color: "#e8454f", fontSize: 50,
            opacity: 0.9,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }} />
          return e
          break;
        case "TEXT_GAME_SNACK":
          e.icon = <SportsEsportsIcon sx={{
            color: "#e8454f", fontSize: 50,
            opacity: 0.9,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }} />
          return e
          break;
        case "HEALTH_SNACK":
          e.icon = <FavoriteBorderIcon sx={{
            color: "#e8454f", fontSize: 50,
            opacity: 0.9,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }} />
          return e
          break;
        case "NEWS_SNACK":
          e.icon = <FlightIcon sx={{
            color: "#e8454f", fontSize: 50,
            opacity: 0.9,
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }} />
          return e
          break;
      }

    })

    return result

  }




  const getCurrentUser = async () => {
    try {
      console.log("in fetch data")
      const response = await axios.get('/api/v1/getUser', {
        timeout: 10000
      });
      console.log("User:", response.data)
      setUser(response.data)


    } catch (error) {
      console.log(error)
      setShowMessages({
        alertType: "error",
        title: "Problem getting user details",
        message: "Please try back at a later time",
      })
    }
  }


  const handleClose = () => {
    setOpen(false)
  }

  const handleEmailClose = () => {
    setEmailOpen(false)
  }

  const handleSubmit = (register) => {


    console.log("data from submit", register)

    axios.post(window.$ip + '/api/v1/addUser', register)
      .then(response => {
        setOpen(false)
        setIsAuthenticated(true)
        navigate("/")
        setUser(response.data)

      }).catch(error => {

        console.log("error", error)
      });
  }

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        console.log("auth result:", authResult)
        // check if new signup
        if (authResult.additionalUserInfo.isNewUser) {
          let register = {
            fullName: authResult.user.multiFactor.user.displayName,
            email: authResult.user.multiFactor.user.email,
            userId: authResult.user.multiFactor.user.uid,
            accountValidated: authResult.user.multiFactor.user.emailVerified
          }
          handleSubmit(register)
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed up.",
          })
          // setSidebar("AllCampaigns")

        } else {
          setIsAuthenticated(true)
          // navigate("/")
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed in.",
          })
        }

        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
      // uiShown: function () {
      //     // The widget is rendered.
      //     // Hide the loader.
      //     document.getElementById('loader').style.display = 'none';
      // }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    // signInSuccessUrl: '/dashboard',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    // tosUrl: '<your-tos-url>',
    // Privacy policy url.
    // privacyPolicyUrl: '<your-privacy-policy-url>'
  };

  const renderDashScreen = () => {
    console.log("in render...", sidebar)
    switch (sidebar) {




      case "Contact":
        return (
          <>
            <Contact
              setShowMessages={setShowMessages}
              dashData={deepDive}
              handleScreenChangeClick={handleScreenChangeClick} updater={updater} />
          </>
        )


      case "PODCAST_SNACK":
        return (

          <MiniPodcast
            // note: getting series w/ EPs
            openLogin={openLogin}
            topPadding={window.$isMobile ? 4 : 2}
          
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}
            isAuthenticated={isAuthenticated}
          />

        )


      case "INTERVIEW_SNACK":
        return (

          <Interview
            // note: getting series w/ EPs
            openLogin={openLogin}
            topPadding={window.$isMobile ? 4 : 2}
          
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}
            isAuthenticated={isAuthenticated}
          />

        )


      case "HomePage":
        return (

          <HomePage
          openLogin={openLogin}
          getSnackMediaPublic={getSnackMediaPublic}
          getSnackMediaPrivate={getSnackMediaPrivate}
          snackMediaPublic={snackMediaPublic}
          snackMediaPrivate={snackMediaPrivate}
          setShowMessages={setShowMessages}
          handleScreenChangeClick={handleScreenChangeClick}
          isAuthenticated={isAuthenticated}
          />

        )



      case "MySeries":
        return (

          <MySeries
            openLogin={openLogin}
            mySeries={snackMediaPrivate}
            getAllMySeries={getSnackMediaPrivate}
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}
            isAuthenticated={isAuthenticated}

          />

        )

      case "PODCAST_SNACK-View":
        return (

          <PodcastView
            // note: getting series w/ EPs
            // openLogin={openLogin}
            // getMySeries={getMySeries}
            userId={user ? user.userId : ""}
            snackCard={snackCard}
            // isAuthenticated={isAuthenticated}
            mySnacks={snackMediaPrivate}
            // getAllSeries={getAllPublicSnackCards}
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}

          />
        )



      case "Feedback":
        return (
          <>
            <Feedback

              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )


      case "Privacy":
        return (
          <>
            <Privacy
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "TOS":
        return (
          <>
            <TOS
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      default:
        return (

          <HomePage
          openLogin={openLogin}
          getSnackMediaPublic={getSnackMediaPublic}
          getSnackMediaPrivate={getSnackMediaPrivate}
          snackMediaPublic={snackMediaPublic}
          snackMediaPrivate={snackMediaPrivate}
          setShowMessages={setShowMessages}
          handleScreenChangeClick={handleScreenChangeClick}
          isAuthenticated={isAuthenticated}
          />

        )
    }

  }

  const handleScreenChangeClick = (data) => {
    console.log("handleScreenChangeClick:", data)
    setUpdater(Math.floor(Math.random() * 10000))
    setDeepDive(data)


    if (data.updateDash) {
      getSnackMediaPublic(0)
      getSnackMediaPrivate(0)
    }

    if (data.snackCard) {
      setSnackCard(data.snackCard)
    }

    if (data.selectedIndex) {
      setSidebar(data.selectedIndex)
    }


    // if (data.series) {
    //   const temp = [...allSnacks]
    //   temp.unshift(data.series)
    //   const cleanArray = removeDuplicateObjects(temp, "seriesId")
    //   setAllSnacks(cleanArray)

    // }
    // if (data.refreshMySeries) {
    //   getAllMySnackCards(0)
    // }
    // if (data.theSeries) {
    //   setTheSeries(data.theSeries)
    // }
    // if (data.episodeClicked) {
    //   const temp = data.theSeries
    //   temp.pods.unshift(data.episodeClicked)
    //   const finalPods = removeDuplicateObjects(temp.pods, "epId")
    //   temp.pods = finalPods
    //   console.log("FINAL ep clicked:", temp)

    //   setTheSeries(temp)

    // }
    // if (data.contentMessage) {
    //   console.log("data.contentMessage: ", data.contentMessage)
    //   setTimeout(() => {
    //     setShowMessages(data.contentMessage)
    //   }, 250)


    // }


  }



  const openLogin = () => {
    setOpen(true)
    ui.start('#firebaseui-auth-container', uiConfig);
    setMobileOpen(false)
  }



  const NestedList = () => {


    return (
      // <ThemeProvider theme={theme}>
      <List
        sx={{
          width: '100%', maxWidth: 360, bgcolor: '#212325', '& .Mui-selected': {
            backgroundColor: '#37383a',
          },
        }}
        selected
        component="nav"
        aria-labelledby="nested-list-subheader"

      >

        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          DataSnack
        </ListSubheader>

        <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "HomePage"}
          onClick={() => {
            setSidebar("HomePage")
            setMobileOpen(false)
          }}
        >

          <SpaceDashboardIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Home</Typography>}
          />
        </ListItemButton>

        {/* <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "ChooseSeries"}
          onClick={() => {
            setSidebar("ChooseSeries")
            setMobileOpen(false)
          }}
        >

          <DescriptionIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>View All PodcastView</Typography>}
          />
        </ListItemButton> */}

        <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "MySeries"}
          onClick={() => {
            if (!isAuthenticated) {
              openLogin()
              return
            }

            setSidebar("MySeries")
            setMobileOpen(false)
          }}
        >

          <ChatIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>My Series</Typography>}
          />
        </ListItemButton>

        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Account
        </ListSubheader>



        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Feedback"}
          onClick={() => {
            setSidebar("Feedback")
            setMobileOpen(false)

          }}>

          <FeedbackIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Submit Feedback</Typography>}
          />
        </ListItemButton>

        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Contact"}
          onClick={() => {
            setSidebar("Contact")
            setMobileOpen(false)

          }}>

          <ContactPageIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Contact Us</Typography>}
          />
        </ListItemButton>

        {!isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={openLogin}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Sign in / Sign up</Typography>}
          />
        </ListItemButton>}

        {isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={() => {
            setIsAuthenticated(false)
            localStorage.removeItem('token');
            setShowMessages({
              alertType: "success",
              title: "Done",
              message: "Successfully signed out.",
            })
            setMobileOpen(false)
            setApiKey(null)
            signOut()
            setSidebar("HomePage")

          }}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Logout</Typography>}
          />
        </ListItemButton>}




      </List>
      // </ThemeProvider>

    );
  }


  return (

    <Box sx={{ display: 'flex' }}>
      <Dialog
        open={open}
        fullWidth={true}
        keepMounted={true}
        sx={{
          //You can copy the code below in your theme
          // background: "#000", // Change to 'red' for example to make it more visible
          "& .MuiPaper-root": {
            background: "transparent", // Change to 'red' for example to make it more visible
            boxShadow: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent" // Try to remove this to see the difference
          }
        }}
        onClose={handleClose}>

        <div style={{
          fullWidth: true,
        }} id="firebaseui-auth-container"></div>


      </Dialog>

      {/* {!isEmailVerified && (
        <Dialog
          open={emailOpen}
          fullWidth={true}


          onClose={handleEmailClose}>
          <DialogTitle sx={{ typography: { fontFamily: "Roboto", fontWeight: 900 }, fontSize: 22, color: "#212325" }}>
            Email verification needed
          </DialogTitle>

          <DialogContent>
            <Typography sx={{ fontSize: 12, typography: { fontFamily: "CourierPrime-Regular" }, color: "#212325" }} fontStyle={"normal"} >
              Please check your recent emails for a verification link. Please click SEND to receive a new verification email.
            </Typography>

          </DialogContent>

          <DialogActions style={{ marginTop: 2 }}>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={handleEmailClose}>Cancel</Button>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={() => {

                firebase.auth().currentUser.sendEmailVerification()
                handleEmailClose()
              }}>Send</Button>
          </DialogActions>

        </Dialog>
      )} */}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}

      >
        <Toolbar sx={{ backgroundColor: "#ffffff" }}>


          <Header
            currentScreen={sidebar}
            setSidebar={setSidebar} user={user}
            handleDrawerToggle={handleDrawerToggle}
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}

            isAuthenticated={isAuthenticated} />

        </Toolbar>
      </AppBar>


      <Drawer

        sx={{
          // display: { xs: 'block', sm: 'none' },

          '& .css-10hburv-MuiTypography-root.css-10hburv-MuiTypography-root': {
            fontFamily: "Roboto", fontWeight: 400,
            fontSize: 12,
            color: "#212325"
          },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#212325",

            // fontFamily: "Roboto", fontWeight: 300,

          },
        }}
        // variant="permanent"
        variant={true ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}

        anchor="left"


      >
        <Toolbar />
        <Divider />
        <NestedList />

      </Drawer>
      <Box
        // component={'Dashboard'}
        sx={{
          flexGrow: 1,
          backgroundColor: '#212325',
          //   p: 3 
        }}
      >
        <Toolbar />
        {showMessages && <ShowMessages msgObj={showMessages} setShowMessages={setShowMessages} />}

        {renderDashScreen()}

      </Box>
    </Box>

  );
}

const snackIcons = [
  {
    icon: <PodcastsIcon sx={{
      color: "#e8454f", fontSize: 50,
      opacity: 0.9,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    }} />,
    title: "Mini-Podcast",
    snackType: "PODCAST_SNACK"
  },
  {
    icon: <SportsEsportsIcon sx={{
      color: "#e8454f", fontSize: 50,
      opacity: 0.9,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    }} />,
    title: "News Adventure",
    snackType: "TEXT_GAME_SNACK"
  },
  {
    icon: <FavoriteBorderIcon sx={{
      color: "#e8454f", fontSize: 50,
      opacity: 0.9,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    }} />,
    title: "Health & Wellness",
    snackType: "HEALTH_SNACK"
  },
  {
    icon: <KeyboardVoiceIcon sx={{
      color: "#e8454f", fontSize: 50,
      opacity: 0.9,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    }} />,
    title: "Interview",
    snackType: "INTERVIEW_SNACK"
  }
]
