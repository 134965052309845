import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-h5-audio-player/lib/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";


// replace console.* for disable log on production

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

if (process.env.NODE_ENV === 'production') {
  window.$dataSnackDomain = "https://www.datasnack.ai"
} else {
  window.$dataSnackDomain = "http://localhost:3000"
}



if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  // true for mobile device
  window.$isMobile = true

} else {
  // false for not mobile device
  window.$isMobile = false
}



var firebaseui = require('firebaseui');

const firebaseConfig = {
  apiKey: "AIzaSyCGPct5rkOU11DBoMt-XCvIFpwoE6DFfHg",
  authDomain: "datasnack.firebaseapp.com",
  projectId: "datasnack",
  storageBucket: "datasnack.appspot.com",
  messagingSenderId: "717248490678",
  appId: "1:717248490678:web:94646c0bf136af532e8a17",
  measurementId: "G-NBS05NCKCF"
};

const app = firebase.initializeApp(firebaseConfig);
let ui = new firebaseui.auth.AuthUI(app.auth());

const analytics = getAnalytics(app);

window.$analytics =  analytics

const root = ReactDOM.createRoot(document.getElementById('root'));

// get time since last login
let currentLoginTime = new Date().getTime()
console.log("currentLoginTime", currentLoginTime)
let lastLoginTime = localStorage.getItem('lastLogin')
if (lastLoginTime !== "") {
  window.$timeSinceLastLoginMins = (currentLoginTime - Number(lastLoginTime)) / (60 * 1000)
  console.log("since last login", window.$timeSinceLastLoginMins, "in mins")
}


//set new login time
let newTime = currentLoginTime.toString()
localStorage.setItem('lastLogin', newTime)


  root.render(
    // <React.StrictMode> 
    <App app={firebase.auth()} ui={ui} />
    // </React.StrictMode> 
    )



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
